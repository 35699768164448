import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import GuessThePersonalityHome from './Components/Apps/GuessThePersonality/Home'
import Header from './Components/Navigation/Header'
import Login from './Components/Login/Login'
import isAuthenticated from './Auth/AuthenticateUser'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SearchQuestion from './Components/Features/SearchQuestion'
import ImageUpload from './Components/Features/ImageUpload'
import ResourceManagement from './Components/Resource/ResourceManagement'
import ResourceList from './Components/Resource/ResourceList'
import ResourceDetails from './Components/Resource/ResourceDetails'
import AddResource from './Components/Resource/AddResource'
import AddCategory from './Components/Resource/AddCategory'
import QuizComponent from './Components/Quiz/QuizComponent'
import ImportExcel from './Components/Quiz/ImportExcel'
import ReorderResources from './Components/Resource/Reorder'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <ToastContainer />
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/apps/guess-the-personality/home' element={<GuessThePersonalityHome />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/image-upload' element={<ImageUpload />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/search-question' element={<SearchQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/management/:resource' element={<ResourceManagement />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/resources/:resource' element={<ResourceList />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reorder/:resource' element={<ReorderResources />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit/:resource/:id' element={<ResourceDetails />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add/:resource' element={<AddResource />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-category' element={<AddCategory />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/quiz/:id' element={<QuizComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/import-excel/:id' element={<ImportExcel />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
