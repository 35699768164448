// src/navItems.js

import { Home, Search, Logout, Image, Folder, Cases, Subject, Topic, Category, SettingsApplications, CalendarMonth, Person, MilitaryTech, Badge, FireTruck } from '@mui/icons-material';

export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <Home />
    },
    {
        value: 'Image Upload',
        route: 'image-upload',
        icon: <Image />
    },
];

export const navItems2 = [
    {
        value: 'Era Expert',
        route: 'apps/era-expert',
        icon: <CalendarMonth />
    },
    {
        value: 'Guess Personality',
        route: 'apps/guess-the-personality/home',
        icon: <Person />
    },
    {
        value: 'Coding Interview',
        route: 'apps/coding-interview',
        icon: <Topic />
    },
    {
        value: 'NDA Practice',
        route: 'apps/nda-practice',
        icon: <FireTruck />
    },
];

export const logoutItem = {
    value: 'Logout',
    route: 'login',
    icon: <Logout />
};
