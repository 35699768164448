import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../../Navigation/MainLayout';
import { Modal, TextField, Button, Chip, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { BASE_URL } from '../../../Api/Constants';
import { showTimeInAgoFormat } from '../../../Utils/Time';

function GuessThePersonalityHome() {
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categoryImageURL, setCategoryImageURL] = useState('');
  const [questionData, setQuestionData] = useState({
    categoryId: '',
    question: '',
    imageURL: '',
    options: ['', '', '', ''],
    correctOption: '',
    explanation: '',
  });
  const [stats, setStats] = useState({
    questionsCount: 0,
    categoriesCount: 0,
    categories: [],
    recentQuestions: [],
  });

  const getDashboardStats = () => {
    axios.get(`${BASE_URL}/api/v1/guess-personality/dashboard-stats`)
      .then(response => {
        setStats(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching stats:', error);
      });
  }

  useEffect(() => {
    getDashboardStats();
  }, []);

  const handleCategorySubmit = () => {
    axios.post(`${BASE_URL}/api/v1/guess-personality/question-categories`, { name: categoryName, imageURL: categoryImageURL })
      .then(response => {
        setCategoryModalOpen(false);
        getDashboardStats();
      })
      .catch(error => {
        console.error('Error submitting category:', error);
      });
  };

  const handleQuestionSubmit = () => {
    axios.post(`${BASE_URL}/api/v1/guess-personality/question`, { ...questionData, options: questionData.options.filter(option => option.trim() !== '') })
      .then(response => {
        setQuestionModalOpen(false);
        getDashboardStats();
      })
      .catch(error => {
        console.error('Error submitting question:', error);
      });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...questionData.options];
    newOptions[index] = value;
    setQuestionData({ ...questionData, options: newOptions });
  };

  const handleCorrectOptionChange = (e) => {
    setQuestionData({ ...questionData, correctOption: e.target.value });
  };

  const handleCategoryChange = (e) => {
    setQuestionData({ ...questionData, categoryId: e.target.value });
  };

  return (
    <MainLayout>
      <div className="p-4">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-extrabold">Guess The Personality</h1>
          <div className="space-x-4">
            <Button
              variant="contained"
              onClick={() => setCategoryModalOpen(true)}
              className="bg-indigo-600 text-white py-2 px-4 rounded shadow hover:bg-indigo-700"
            >
              Add Category
            </Button>
            <Button
              variant="contained"
              onClick={() => setQuestionModalOpen(true)}
              className="bg-green-600 text-white py-2 px-4 rounded shadow hover:bg-green-700"
            >
              Add Question
            </Button>
          </div>
        </div>

        {/* Display Stats */}
        <div className="grid grid-cols-1 gap-6 mb-8">
          <div className="bg-white rounded-lg p-6 flex justify-between shadow-md">
            <div className="flex-1">
              <h2 className="text-xl font-bold mb-2">Questions Count</h2>
              <p className="text-gray-600 text-3xl font-extrabold">{stats.questionsCount}</p>
            </div>
            <div className="flex-1">
              <h2 className="text-xl font-bold mb-2">Categories Count</h2>
              <p className="text-gray-600 text-3xl font-extrabold">{stats.categories.length}</p>
            </div>
          </div>

          <div className="bg-white rounded p-6 shadow-md">
            <h2 className="text-xl font-bold mb-2">Categories</h2>
            <div className="flex flex-wrap gap-2">
              {stats.categories.map((category, index) => (
                <Chip sx={{ cursor: 'pointer'}} key={index} color="info" label={category.name} />
              ))}
            </div>
          </div>
        </div>

        <div className="bg-white p-8">
          <h2 className="text-2xl font-bold mb-4">Recently Added Questions</h2>
          <table className="min-w-full bg-white rounded-lg">
            <thead>
              <tr>
                <th className="py-2 px-4 rounded-l-lg bg-gray-600 text-white">#</th>
                <th className="py-2 px-4 bg-gray-600 text-white text-left">Question</th>
                <th className="py-2 px-4 bg-gray-600 text-white text-left">Answer</th>
                <th className="py-2 px-4 bg-gray-600 rounded-r-lg text-white text-left">Created At</th>
              </tr>
            </thead>
            <tbody>
              {stats.recentQuestions.map((question, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4 text-center">{index + 1}</td>
                  <td className="py-2 px-4">{question.question}</td>
                  <td className="py-2 px-4">{question.correctOption}</td>
                  <td className="py-2 px-4">{showTimeInAgoFormat(question.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Category Modal */}
        <Modal open={categoryModalOpen} onClose={() => setCategoryModalOpen(false)}>
          <div className="bg-gray-100 rounded-lg shadow-lg p-8 overflow-y-auto mx-auto mt-20 max-w-4xl w-11/12 h-5/6">
            <h2 className="text-2xl font-bold mb-4 text-indigo-800">Add Category</h2>
            <div className="space-y-4">
              <TextField
                label="Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
              <TextField
                label="Image URL"
                value={categoryImageURL}
                onChange={(e) => setCategoryImageURL(e.target.value)}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCategorySubmit}
              sx={{ mt: 4 }}
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow hover:bg-indigo-700 mt-6"
            >
              Submit
            </Button>
          </div>
        </Modal>

        {/* Question Modal */}
        <Modal open={questionModalOpen} onClose={() => setQuestionModalOpen(false)}>
          <div className="bg-gray-100 rounded-lg shadow-lg p-8 overflow-y-auto mx-auto mt-20 max-w-4xl w-11/12 h-5/6">
            <h2 className="text-2xl font-bold mb-4 text-indigo-800">Add Question</h2>
            <div className="space-y-4">
              <FormControl fullWidth variant="outlined" className="mb-4">
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  value={questionData.categoryId}
                  onChange={handleCategoryChange}
                  label="Category"
                  className="rounded-md border-gray-300"
                >
                  {stats.categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Question"
                value={questionData.question}
                onChange={(e) => setQuestionData({ ...questionData, question: e.target.value })}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
              <TextField
                label="Image URL"
                value={questionData.imageURL}
                onChange={(e) => setQuestionData({ ...questionData, imageURL: e.target.value })}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
              <TextField
                label="Option 1"
                value={questionData.options[0]}
                onChange={(e) => handleOptionChange(0, e.target.value)}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
              <TextField
                label="Option 2"
                value={questionData.options[1]}
                onChange={(e) => handleOptionChange(1, e.target.value)}
                fullWidth
                variant
                ="outlined"
                className="rounded-md border-gray-300"
              />
              <TextField
                label="Option 3"
                value={questionData.options[2]}
                onChange={(e) => handleOptionChange(2, e.target.value)}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
              <TextField
                label="Option 4"
                value={questionData.options[3]}
                onChange={(e) => handleOptionChange(3, e.target.value)}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
              <FormControl fullWidth variant="outlined" className="mb-4">
                <InputLabel id="correct-option-label">Correct Option</InputLabel>
                <Select
                  labelId="correct-option-label"
                  value={questionData.correctOption}
                  onChange={handleCorrectOptionChange}
                  label="Correct Option"
                  className="rounded-md border-gray-300"
                >
                  {questionData.options.map((option, index) => (
                    <MenuItem key={index} value={option.trim()}>{option.trim()}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Explanation"
                value={questionData.explanation}
                onChange={(e) => setQuestionData({ ...questionData, explanation: e.target.value })}
                fullWidth
                variant="outlined"
                className="rounded-md border-gray-300"
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleQuestionSubmit}
              className="w-full bg-green-600 text-white py-2 px-4 rounded-lg shadow hover:bg-green-700 mt-6"
            >
              Submit
            </Button>
          </div>
        </Modal>
      </div>
    </MainLayout>
  );
}

export default GuessThePersonalityHome;